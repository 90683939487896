import {
  MdLightbulbOutline,
  MdOutlineDesignServices,
  MdOutlineBuildCircle,
  MdOutlineBiotech,
  MdOutlineIntegrationInstructions,
} from "react-icons/md";
import {
  Avatar_ayinde_img,
  Avatar_cyprien_img,
  Avatar_nidal_img,
} from "@/public";
import Image from "next/image";
import {
  FaNodeJs,
  FaVuejs,
  FaReact,
  FaJava,
  FaPython,
  FaAws,
  FaRegLightbulb,
  FaProjectDiagram,
  FaUsersCog,
  FaDatabase,
  FaUserPlus,
  FaRunning,
  FaFigma,
} from "react-icons/fa";
import { SiTerraform, SiUml, SiCplusplus } from "react-icons/si";
import {
  MdArchitecture,
  MdIntegrationInstructions,
  MdDashboard,
} from "react-icons/md";
import { DiScrum } from "react-icons/di";
import { RiFlowChart } from "react-icons/ri";
import { GiModernCity, GiChessKnight } from "react-icons/gi";
import { VscAzureDevops } from "react-icons/vsc";

const ourServices = [
  {
    icon: <MdLightbulbOutline />,
    num: "01",
    title: "Idéation",
    description:
      "Processus créatif combinant génération d'idées, filtrage stratégique, et évaluation par IA pour aligner projets et valeurs d'entreprise. Un mélange d'émulation et de technologie pour des solutions innovantes.",
  },
  {
    icon: <MdOutlineDesignServices />,
    num: "02",
    title: "Conception",
    description:
      "Définition structurée de l'architecture produit et application de patterns à différents niveaux pour préparer une industrialisation efficace. Un équilibre entre innovation et pragmatisme.",
  },
  {
    icon: <MdOutlineBuildCircle />,
    num: "03",
    title: "Réalisation",
    description:
      "Engagement pour le Clean Code, valorisation des revues croisées, et pratique du Pair Programming pour une exécution de haute qualité. Excellence technique au cœur de la réalisation.",
  },
  {
    icon: <MdOutlineBiotech />,
    num: "04",
    title: "Tests",
    description:
      "Approche segmentée (POC, MVP) intégrant des Releases Continues pour minimiser les risques, appuyée par des démos régulières. Qualification rigoureuse pour des solutions fiables.",
  },
  {
    icon: <MdOutlineIntegrationInstructions />,
    num: "05",
    title: "Méthode",
    description:
      "Synergie des méthodes Agile, Lean, et SAFe enrichies par une comitologie adaptative et une ritualisation des processus. Flexibilité et amélioration continue pour exceller.",
  },
];

const profilHeader = [
  {
    name: "CD",
    avatar: (
      <Image
        src={Avatar_cyprien_img}
        alt="avatar"
        className="w-full h-full object-center object-contain"
      />
    ),
  },
  {
    name: "NZ",
    avatar: (
      <Image
        src={Avatar_nidal_img}
        alt="avatar"
        className="w-full h-full object-center object-contain"
      />
    ),
  },
  {
    name: "ALM",
    avatar: (
      <Image
        src={Avatar_ayinde_img}
        alt="avatar"
        className="w-full h-full object-center object-contain"
      />
    ),
  },
];

const profilContent = [
  {
    name: "Cyprien Dendiével",
    avatar: (
      <Image
        src={Avatar_cyprien_img}
        alt="avatar"
        className="w-full h-full object-center object-contain"
      />
    ),
    content:
      "Direction informatique avec une longue expérience en gestion de projets, pilotage d’activités, stratégie et management. Expertise dans le domaine de la data, l’urbanisation, le schéma directeur et la stratégie, ainsi que l’onboarding d’équipes. Compétences en méthodologies Agile, SAFe, Lean et innovation.",
    skillList: [
      {
        icon: <FaProjectDiagram />,
        name: "Direction de projet",
      },
      {
        icon: <MdDashboard />,
        name: "Pilotage d’activité",
      },
      {
        icon: <GiChessKnight />,
        name: "Stratégie",
      },
      {
        icon: <FaUsersCog />,
        name: "Management",
      },
      {
        icon: <FaDatabase />,
        name: "Data",
      },
      {
        icon: <GiModernCity />,
        name: "Urbanisation",
      },
      {
        icon: <RiFlowChart />,
        name: "Schéma Directeur",
      },
      {
        icon: <FaUserPlus />,
        name: "Onboarding d’équipes",
      },
      {
        icon: <FaRegLightbulb />,
        name: "Innovation",
      },
      {
        icon: <FaRunning />,
        name: "Agile / SAFe / Lean",
      },
    ],
  },
  {
    name: "Nidal Zoumita",
    avatar: (
      <Image
        src={Avatar_nidal_img}
        alt="avatar"
        className="w-full h-full object-center object-contain"
      />
    ),
    content:
      "Fullstack avec une expertise en NodeJS, VueJS, ReactJS, ainsi qu’en Java, JEE, Python. Expérimenté en DevOps, y compris le Cloud AWS, Azure Devops, la mise en place de CI/CD et Terraform. Familiarisé avec des méthodes agiles telles que Agile-Scrum, et une solide compréhension de l’architecture système.",
    skillList: [
      {
        icon: <FaNodeJs />,
        name: "Node.js",
      },
      {
        icon: <FaVuejs />,
        name: "Vue.js",
      },
      {
        icon: <FaReact />,
        name: "React",
      },
      {
        icon: <FaJava />,
        name: "Java / JEE / SpringBoot",
      },
      {
        icon: <FaPython />,
        name: "Python",
      },
      {
        icon: <FaAws />,
        name: "Cloud AWS",
      },
      {
        icon: <VscAzureDevops />,
        name: "Azure Devops",
      },
      {
        icon: <SiTerraform />,
        name: "Terraform",
      },
      {
        icon: <MdIntegrationInstructions />,
        name: "CI/CD",
      },
      {
        icon: <SiUml />,
        name: "Modélisation (UML, Merise...)",
      },
      {
        icon: <MdArchitecture />,
        name: "Architecture (Micro-services, MVC, Clean Code)",
      },
      {
        icon: <DiScrum />,
        name: "Agile-Scrum",
      },
      {
        icon: <FaRegLightbulb />,
        name: "Innovation",
      },
    ],
  },
  {
    name: "Ayindé Louisin-Manigat",
    content:
      "Fullstack spécialisé en NodeJS, VueJS, ReactJS, avec des compétences supplémentaires en C, C++ et Python. Compétences en DevOps sur le Cloud AWS et Azure Devops, et mise en place de CI/CD. Approche méthodologique comprenant Agile-Scrum, l’innovation, le Design Thinking et le Design Process, avec un accent particulier sur les besoins métiers (UX/UI, Figma, Responsive).",
    avatar: (
      <Image
        src={Avatar_ayinde_img}
        alt="avatar"
        className="w-full h-full object-center object-contain"
      />
    ),
    skillList: [
      {
        icon: <FaNodeJs />,
        name: "Node.js",
      },
      {
        icon: <FaVuejs />,
        name: "Vue.js",
      },
      {
        icon: <FaReact />,
        name: "React",
      },
      {
        icon: <SiCplusplus />,
        name: "C/C++",
      },
      {
        icon: <FaPython />,
        name: "Python",
      },
      {
        icon: <FaAws />,
        name: "Cloud AWS",
      },
      {
        icon: <VscAzureDevops />,
        name: "Azure Devops",
      },
      {
        icon: <MdIntegrationInstructions />,
        name: "CI/CD",
      },
      {
        icon: <FaFigma />,
        name: "Figma",
      },
      {
        icon: <DiScrum />,
        name: "Agile-Scrum",
      },
      {
        icon: <FaRegLightbulb />,
        name: "Innovation",
      },
      {
        icon: <MdOutlineDesignServices />,
        name: "Design Thinking / Design Process",
      },
    ],
  },
];

export { ourServices, profilHeader, profilContent };
